import { porttcfolio } from "./CryptoData";

//needed for json parse/jsonify - do not remove
Date.prototype.toJSON = function () {
    return this.getTime().toString();
}

export const updatePortfolioInBrowserCache = () => {
    let strPorttcfolio = JSON.stringify(porttcfolio);
    localStorage.setItem("cryptool", strPorttcfolio);
}

export const getCachePrice = (symbol: string, name: string,coingeckoId: any) => {
    let expirationStorage: any = localStorage.getItem("cryptoverviewExpiration");
    if (expirationStorage != null && expirationStorage !== undefined) {
        expirationStorage = JSON.parse(expirationStorage);
        let cryptoExpiration;
        if(coingeckoId!=null){
            cryptoExpiration=expirationStorage[symbol.toUpperCase() + "-" + coingeckoId.toUpperCase()];
        }else{
            cryptoExpiration=expirationStorage[symbol.toUpperCase() + "-" + name.toUpperCase()];
        }
        
        if (cryptoExpiration == null || cryptoExpiration === undefined) {
            return null;
        } else {
            let expiration: Date = cryptoExpiration.expiration;
            if (cryptoExpiration && ((new Date()) < expiration)) {
                return cryptoExpiration.value;
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
}

export const getCryptoExpiration = (symbol: string, name: string,coingeckoId:any) => {
    let expirationStorage: any = localStorage.getItem("cryptoverviewExpiration");
    if (expirationStorage != null && expirationStorage !== undefined) {
        expirationStorage = JSON.parse(expirationStorage);
        let cryptoExpiration;
        if(coingeckoId!=null){
            cryptoExpiration=expirationStorage[symbol.toUpperCase() + "-" + coingeckoId.toUpperCase()];
        }else{
            cryptoExpiration=expirationStorage[symbol.toUpperCase() + "-" + name.toUpperCase()];
        }
        
        if (cryptoExpiration == null || cryptoExpiration === undefined) {
            return null;
        } else {
            let expiration: Date = cryptoExpiration.expiration;
            if (cryptoExpiration && ((new Date()) < expiration)) {
                if ("NULL" === cryptoExpiration.price_change_percentage_24h) {
                    cryptoExpiration = 0;
                }
                return cryptoExpiration;
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
}

export const updateCacheCryptoExpiration = (symbol: string, name: string | undefined, value: any, coingeckoId: string, history: string | undefined, price_change_percentage_24h: number | null) => {
    let expirationStorage: any = localStorage.getItem("cryptoverviewExpiration");
    if (expirationStorage == null || expirationStorage === undefined) {
        expirationStorage = {};
    } else {
        expirationStorage = JSON.parse(expirationStorage);
    }
    let expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + 10);
    let data= {
        'value': value, 'expiration': expirationDate, 'coingeckoId': coingeckoId, 'history': history
        , 'price_change_percentage_24h': ((price_change_percentage_24h == null || price_change_percentage_24h === undefined) ?
            ("NULL" === expirationStorage.price_change_percentage_24h ? 0 : expirationStorage.price_change_percentage_24h) : price_change_percentage_24h)
    };;
    
    if(coingeckoId!=null){
        expirationStorage[symbol.toUpperCase() + "-" + coingeckoId.toUpperCase()] =data;   
    }else{
        expirationStorage[symbol.toUpperCase() + "-" + (name !== undefined ? name.toUpperCase() : symbol.toUpperCase())] =data;
    }
    localStorage.setItem("cryptoverviewExpiration", JSON.stringify(expirationStorage));
}