import { forwardRef, useRef } from "react";
import { porttcfolio } from "./data/CryptoData";
import CryptoHeaderSummaryDialog from "./CryptoHeaderSummaryDialog";
import FieldEditor from "./array/FieldEditor";

const CryptoHeaderSummary = forwardRef(( props:any,ref:any  ) => {
    const summaryRef = useRef(null);
    let currentTotal = Number(porttcfolio.currentTotal);


    const doDetails = () => {
        if (summaryRef.current) {
          (summaryRef.current as any).handleOpen();
        }
      }


    return <span className="crypto-summary left">
        <CryptoHeaderSummaryDialog ref={summaryRef}
        impotsPerc={props.impotsPerc}
        viewMode={props.viewMode}/>
       
        <br /><table>
            <tbody>
                <tr><td>Actuel:</td><td>{porttcfolio.transient?.totalBeforeTax?.toFixed(0)} USDT</td></tr>
                <tr><td colSpan={2}> <button onClick={doDetails} className="ttcButton"> details</button></td></tr>
            </tbody>
        </table>
    </span>;
});

export default CryptoHeaderSummary;