"use client";

import * as PIXI from "pixi.js";
import React, { useEffect, useMemo, useState } from "react";
import { CryptoTableRows } from "../data/CryptoDataType";
import { Circle, PriceChangePercentage } from "./bubbles.types";
import { BubblesUtils, appConfig } from "./bubbles.util";
import { PixiUtils } from "./pixi.util";
import { Box, Switch, Typography } from "@mui/material";


type Props = {
  coins: CryptoTableRows;
};

const { width, height, maxCircleSize, minCircleSize } = appConfig;

export default function Bubbles({ coins }: Props) {

  console.log("TODO CST bubbles");

  console.log(coins);
  const [circles, setCircles] = useState<Circle[] | null>(null);
  let bubbleSort=PriceChangePercentage.HOUR;

  const appRef = React.useRef<HTMLDivElement>(null);

  const scalingFactor = useMemo(() => {
    return BubblesUtils.getScalingFactor(coins, bubbleSort);
  }, [bubbleSort, coins]);

  useEffect(() => {
    if (coins) {
      const scalingFactor = BubblesUtils.getScalingFactor(coins, PriceChangePercentage.HOUR);
      const shapes = BubblesUtils.generateCircles(coins, scalingFactor);
      setCircles(shapes);
    }
  }, [coins]);

  useEffect(() => {
    if (!circles) return;
    const imageSprites: PIXI.Sprite[] = [];
    const textSprites: PIXI.Text[] = [];
    const text2Sprites: PIXI.Text[] = [];
    const circleGraphics: PIXI.Sprite[] = [];

    const app = new PIXI.Application({
      width: width,
      height,
      backgroundColor: 0x0e1010
    }) as unknown;

    const appContainer = appRef.current;

    if (appContainer != null) {
      appContainer.appendChild((app as { view: Node }).view);
      appContainer.children[0].addEventListener("click", (e: unknown) => {
        BubblesUtils.handleEmptySpaceClick(e as MouseEvent, circles);
        document.getElementById("bubbles")!.classList.add("hide");
      }
      );
    }

    for (let i = 0; i < circles.length; i++) {
      const circle = circles[i];

      const container = PixiUtils.createContainer(circle);

      const imageSprite = PixiUtils.createImageSprite(circle);
      imageSprites.push(imageSprite);
      container.addChild(imageSprite);

      const circleGraphic = new PIXI.Sprite(PixiUtils.createGradientTexture(circle.radius * 4, circle.color));
      circleGraphic.anchor.set(0.5);
      circleGraphics.push(circleGraphic);
      container.addChild(circleGraphic);

      // Create the text
      const text = PixiUtils.createText(circle);
      container.addChild(text);
      textSprites.push(text);

      // Create the second text
      const text2 = PixiUtils.createText2(circle, PriceChangePercentage.HOUR);

      container.addChild(text2);
      text2Sprites.push(text2);


      (app as PIXI.Application).stage.addChild(container);
    }

    const ticker = BubblesUtils.update(circles, imageSprites, textSprites, text2Sprites, circleGraphics);
    setTimeout(() => {
      (app as PIXI.Application).ticker.add(ticker);
    }, 200);

    return () => {
      (app as PIXI.Application).ticker.remove(ticker);
      if (appContainer != null && appContainer.children[0] != null) {
        appContainer.children[0].removeEventListener("click", (e: unknown) => BubblesUtils.handleEmptySpaceClick(e as MouseEvent, circles));
      }
    };
  }, [circles]);

  useEffect(() => {
    if (circles) {
      const max = maxCircleSize;
      const min = minCircleSize;

      circles.forEach((circle) => {
        if (!circle[bubbleSort]) return;

        const radius = Math.abs(Math.floor(circle[bubbleSort] * scalingFactor));
        circle.targetRadius = radius > max ? max : radius > min ? radius : min;
        circle.color = circle[bubbleSort] > 0 ? "green" : "red";
        if (circle.text2) {
          circle.text2.text = (circle.price_change_percentage_1h_in_currency && circle.price_change_percentage_1h_in_currency !== "NULL" ? circle[bubbleSort].toFixed(2) + "%" : "");
        }
      });
    }
  }, [bubbleSort, coins, circles, scalingFactor]);

  const [daily, setDaily] = useState(false);

  const handleChange = (event:any) => {
  //
    setDaily(event.target.checked);
  };

  return (
    <div id="bubbles" className="flex rounded px-2 overflow-hidden bg-zinc-900 md:flex-col flex-col-reverse">
      <div style={{ height: "100%" }} className="bg-zinc-900 w-full overflow-hidden border-2 rounded border-gray-800" ref={appRef}></div>
      <div className="bubblesHeader">
      <Box display="flex" alignItems="center">
      <Typography sx={{ mr: 2 }}>Journalier</Typography>
      <Switch
          checked={daily}
          onChange={handleChange}
          color="primary"
        />
        <Typography sx={{ mr: 2 }}>Bimensuel</Typography>
        </Box>
        </div>
    </div>
  );
}
