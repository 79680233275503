import axios from "axios";
import { CryptoTableRows } from "./CryptoDataType";
import { calculPrice, porttcfolio } from "./CryptoData";
import { getCachePrice, updateCacheCryptoExpiration, updatePortfolioInBrowserCache } from "./CryptoCache";

const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

export const queryCoinGecko = async (setTtcCryptos: any, filterData: any) => {

  while (notAllLoaded()) {
    await delay(1000);
  }

  
  let total=0;  
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let item =  porttcfolio.items[i];
    let price = item.bagPrice;
    if (price != null) {
      total += price;
    }
   // console.log(item.symbol+" - "+item.site +" - "+price+" TOTAL="+total);
  }
  porttcfolio.currentTotal=total;

  axios
    .get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=2000&page=1&sparkline=false"
    )
    .then((res) => {
      let data: any = res.data;
      let result: CryptoTableRows;
      for (let j = 0; j < data.length; j++) {
        let srcData = data[j];
        srcData.symbol = srcData.symbol.toUpperCase();
      }
      result = { items: filterData(data) };
      setTtcCryptos(result);
    })
    .catch((error) => {
      console.log(error);
      let result: CryptoTableRows;
      result = { items: filterData([]) };
      setTtcCryptos(result);
    });

}




export async function loadPrices() {
 // let symbols = '';
  let symbolsArray:any[]=[];
  let previousSymbol = '';
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (previousSymbol !== (pf.symbol + '|' + pf.name)) {
   /*   if (symbols !== '') {
        symbols = symbols + '_';
      }
      symbols = symbols + pf.symbol + '|' + pf.name;
      */
      symbolsArray.push({symbol:pf.symbol,name:pf.name,coingeckoId:pf.coingeckoId});
    }
    previousSymbol = pf.symbol + '|' + pf.name;
  }

//  getGeekiPrices(symbols);
getGeekiPrices2(symbolsArray);
}

export const getGeekiPrices2 = (symbols: any[]) => {
  if (symbols != null && symbols !== undefined ) {
    axios
      .post(
        "https://www.geeki.fr/api/cryptos2/" ,{cryptos:symbols}, {          
        withCredentials: false,
        headers: {
          'Accepts': 'application/json',
          'Access-Control-Allow-Credentials': true,
          "Access-Control-Allow-Origin": "*",
          'Access-Control-Allow-Headers': 'Origin'
        }
      })
      .then((res) => {
        let resData = res.data;
        for (let i = 0; i < resData.length; i++) {

          let data = resData[i];
          let pf = porttcfolio.items;
          let pfj;
          for (let j = 0; j < pf.length; j++) {
            pfj = pf[j];
            pfj.symbol = pfj.symbol.split("|")[0];
            console.log("TODO CST data.symbol:"+data.symbol );
            if (pfj.symbol.toUpperCase() === data.symbol.toUpperCase()
              && (
            (
              (pfj.coingeckoId!=null && data.coingeckoId!=null && pfj.coingeckoId.toUpperCase()===data.coingeckoId.toUpperCase())
              ||(
            pfj.name == null || pfj.name === undefined
             || pfj.name!.toUpperCase() === data.name.toUpperCase())))) {
              pfj.symbol =  data.symbol.toUpperCase();
              pfj.name =  data.name;
              pfj.coingeckoId = data.coingeckoId;
              pfj.unitPrice = data.price;
              pfj.bagPrice = calculPrice(pfj.bag, data.price);
              pfj.price_change_percentage_24h = isNaN(data.price_change_percentage_24h) ? Number(data.price_change_percentage_24h) : data.price_change_percentage_24h;
              pfj.market_cap = data.market_cap;
              pfj.market_cap_rank = data.market_cap_rank;
              pfj.ath = data.ath;
              pfj.image = data.image;
              pfj.history = data.history;
              pfj.loaded = true;
              updateCacheCryptoExpiration(pfj.symbol, pfj.name, pfj.unitPrice, data.coingeckoId, pfj.history, data.price_change_percentage_24h);
            }
          }
        }
        updatePortfolioInBrowserCache();
        doCoinbases();
      })
      .catch((error) => {
        console.log(error);
        updatePortfolioInBrowserCache();
      })
  }
}


export const getGeekiPrices = (symbols: string) => {
  if (symbols != null && symbols !== undefined && symbols !== "") {
    symbols = symbols.toUpperCase();
    axios
      .get(
        "https://www.geeki.fr/api/cryptos/" + encodeURIComponent(symbols), {
        withCredentials: false,
        headers: {
          'Accepts': 'application/json',
          'Access-Control-Allow-Credentials': true,
          "Access-Control-Allow-Origin": "*",
          'Access-Control-Allow-Headers': 'Origin'
        }
      })
      .then((res) => {
        let resData = res.data;
        for (let i = 0; i < resData.length; i++) {

          let data = resData[i];
          let pf = porttcfolio.items;
          let pfj;
          for (let j = 0; j < pf.length; j++) {
            pfj = pf[j];
            pfj.symbol = pfj.symbol.split("|")[0];
            let splitted = data.symbol.split("|");
            if (splitted[1] === undefined) {
              splitted[1] = pfj.name;
            }
            if (pfj.symbol.toUpperCase() === splitted[0].toUpperCase()
              && (pfj.name == null || pfj.name === undefined || pfj.name!.toUpperCase() === splitted[1].toUpperCase())) {
              pfj.symbol = splitted[0].toUpperCase();
              pfj.name = splitted[1];
              pfj.unitPrice = data.price;
              pfj.bagPrice = calculPrice(pfj.bag, data.price);
              pfj.price_change_percentage_24h = isNaN(data.price_change_percentage_24h) ? Number(data.price_change_percentage_24h) : data.price_change_percentage_24h;
              pfj.market_cap = data.market_cap;
              pfj.market_cap_rank = data.market_cap_rank;
              pfj.ath = data.ath;
              pfj.image = data.image;
              pfj.history = data.history;
              pfj.loaded = true;
              pfj.coingeckoId = data.coingeckoId;

              updateCacheCryptoExpiration(pfj.symbol, pfj.name, pfj.unitPrice, data.coingeckoId, pfj.history, data.price_change_percentage_24h);
            }
          }
        }
        updatePortfolioInBrowserCache();
        doCoinbases();
      })
      .catch((error) => {
        console.log(error);
        updatePortfolioInBrowserCache();
      })
  }
}


export async function doCoinbases() {
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (!pf.loaded) {
      try {
        queryCoinbase(pf.symbol, pf.name === undefined ? '' : pf.name, pf);
      } catch (e) {
        console.log(e);
        pf.loaded = true;
      };
    }
  }


}

export async function queryCoinbase(symbol: string, name: string, pf: any) {
  try {
    let coingeckoId:any=null;
    let cryptoPrice = getCachePrice(symbol, name,coingeckoId);
    if (cryptoPrice != null && cryptoPrice !== 0) {
      pf.unitPrice = cryptoPrice;
      pf.loaded = true;
    } else {
      const res = await axios.get("https://api.coinbase.com/v2/prices/" + symbol + "-USD/spot");

      if (typeof pf.unitPrice === 'string') {
        pf.unitPrice = Number(pf.unitPrice);
      } else {
        pf.unitPrice = res.data.data.amount;
      }
      pf.loaded = true;
      updateCacheCryptoExpiration(symbol, pf.name, pf.unitPrice, pf.coingeckoId, pf.history, null);
    }
  } catch (e) {
    pf.unitPrice = 0;
    pf.loaded = true;
    for (let i = 0; i < porttcfolio.items.length; i++) {
      if (porttcfolio.items[i].ttcId === pf.ttcId) {
        porttcfolio.items[i] = pf;
        break;
      }
    }
    updateCacheCryptoExpiration(symbol, pf.name, pf.unitPrice, pf.coingeckoId, pf.history, null);
  }

}

export const notAllLoaded = () => {
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (!pf.loaded) {
      return true;
    }
  }
  return false;
}