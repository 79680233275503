import { useState } from "react";
import "../CryptoTable.css";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import TargetEditor from "./TargetEditor";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { StatusEnum } from "../data/CryptoDataType";
import StatusEditor from "./StatusEditor";
import SellIcon from '@mui/icons-material/Sell';
import { toFixed } from "../data/CryptoData";


const CryptoRowTarget = (props: any) => {

    let [updated, setUpdated] = useState(false);

    const toggleUpdated = () => {
        setUpdated(!updated);
        props.partialRefresh(false);
    }
    const partialRefresh = () => {
        props.partialRefresh(false);
    }

    const onRemove = () => {
        props.onRemoveTarget(props.targetPos);
    }

    let row = props.row;
    let targetPos = props.targetPos;
    let item = props.item;
    
    if (!row.targets) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if ((!row.targets[targetPos])) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if (row.targets.length > targetPos) {
        let numPrice = row.targets[targetPos].price;
        let price = (numPrice == null || numPrice === undefined ? null : numPrice.toFixed(2));
        let numBag = row.targets[targetPos].bag;
        let bag = (numBag == null || numBag === undefined ? null : numBag.toFixed(2));

        let target1Perc = row.targets[targetPos].perc;
        let cssTarget1Perc=toFixed(100-target1Perc,0);
        let gradiant="rgba(100,100,100,0.1)";
        if(target1Perc>=80){
            gradiant="rgba(100,255,100,0.1)";
        }else if(target1Perc>=50){
            gradiant="rgba(238, 173, 0,0.1)";
        }

        let background="lightblue";
        let isSold=row.targets[targetPos].sold;
        if(!isSold){
            background="linear-gradient( rgba(0,0,0,0) 0%,rgba(0,0,0,0) "+cssTarget1Perc+"%, "+gradiant+" "+cssTarget1Perc+"%, "+gradiant+" 100%) ";
        }

        return (<TableCell  align="right" className={"rowTarget removeTarget"+(isSold?" sold":"")}
        style={{background:background}}
            colSpan={row.targets[targetPos].bagPerc}>

            <TargetEditor
                tooltip="Cours de vente en USDT"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="rate"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key12" + item.name + item.site + Math.random()} />
            <div className="fieldEditor-container"><TargetEditor
                    tooltip="Pourcentage du bag"
                    className="fixedWidth"
                    targetPos={targetPos} val={row} prop="bagPerc"
                    viewMode={props.viewMode}
                    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                    onChange={toggleUpdated} type="percentage" key={"key131" + item.name + item.site + Math.random()} /></div>
            {props.viewMode ? <div className="fixedWidth alignRight"><Tooltip title="Montant de l'objectif"><div>$ {price}</div></Tooltip></div>: <br />}

            {props.viewMode &&(row.targets[targetPos].status === StatusEnum.Order)
                && (            <div className="fixedWidth">{row.targets[targetPos].status === StatusEnum.Order && <span className="targetOrdered">
                <Tooltip title="Ordre de vente"><SellIcon />
                </Tooltip>
            </span>}
            
            {props.viewMode ? (<Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip>): <br />}</div>)}
            {props.viewMode && (row.targets[targetPos].status !== StatusEnum.Order)
    && <div className="fixedWidth alignRight"><Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip></div>}
            
            
            { (!props.viewMode) && <Tooltip title="Statut de l'ordre"><div><StatusEditor targetPos={targetPos} val={row} /></div></Tooltip>}

            {row.targets[targetPos].status === StatusEnum.Sold && <div>&nbsp;</div>}
{row.targets[targetPos].status === StatusEnum.Sold && <div className="fieldEditor-container"><div>&nbsp;</div>
            <TargetEditor
                    tooltip="Montant réinvesti. Le montant ne sera pas utilisé pour calculer les gains car il est réinvesti."
                    className="fixedWidth"
                    targetPos={targetPos} val={row} prop="reinvestedAmount"
                    viewMode={props.viewMode}
                    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                    onChange={toggleUpdated} type="currency" key={"key1321" + item.name + item.site + Math.random()} /></div>

}

            {row.targets.length - 1 === targetPos &&
                <Tooltip title="Supprimer objectif"><IconButton onClick={onRemove} className="removeTarget">
                    <RemoveIcon />
                </IconButton></Tooltip>
            }
           {(!isSold)&&  <Tooltip title="% Objectif réalisé"><span className="percTarget">{toFixed(row.targets[targetPos].perc,2)}%</span></Tooltip>}
            {isSold && <span className="percTarget">Vendu</span>}
        </TableCell>
        );
    } else {
        return (<TableCell key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    }

}

export default CryptoRowTarget;