import geekiExchangesRequest from "../exchange/mexc/exchanges";
import { getCryptoExpiration, updatePortfolioInBrowserCache } from "./CryptoCache";
import { CryptoTableRow, StatusEnum, TargetData, generateTtcId, mapCoinGeckoId } from "./CryptoDataType";
import { loadPrices } from "./CryptoQuery";
import { calculPrice, porttcfolio } from "./CryptoData";

export const sortPorttcfolio = () => {
  let obj = porttcfolio.items;
  obj.sort((a: any, b: any) => (a.symbol.toUpperCase() > b.symbol.toUpperCase()) ? 1 : ((b.symbol.toUpperCase() > a.symbol.toUpperCase()) ? -1 : 0))
  updatePortfolioInBrowserCache();
}

export const initPorttcfolio = () => {
  initExchanges(initPorttcfolioStep2);
}

const initPorttcfolioStep2 = () => {
  if (!porttcfolio.impotsPerc) {
    porttcfolio.impotsPerc = 33.0;
  }
  loadPrices();
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (pf.ttcId == null || pf.ttcId === undefined) {
      pf.ttcId = generateTtcId();
    }
    for (let j = 0; j < pf.targets.length; j++) {
      if (pf.targets[j].status == null || pf.targets[j].status === undefined) {
        pf.targets[j].status = StatusEnum.Init;
      }
    }


  }

  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    pf.loaded = true;
  }

  sortPorttcfolio();
}

const initExchanges = (initPorttcfolioStep2: any) => {
  if (porttcfolio.exchanges) {
    geekiExchangesRequest(porttcfolio.exchanges).then(response => {
       // console.log(response);
      let data = (response as any).data;
      for (let i = 0; i < data.result.length; i++) {
        updateExchangeData(data.result[i].exchange, data.result[i].symbol, data.result[i].bag);
        //TODO CST manage removed
      }
      initPorttcfolioStep2();
    })
      .catch(error => {
        console.error('Une erreur est survenue', error);
      });
  } else {
    porttcfolio.exchanges = [];
    initPorttcfolioStep2();
  }

}

const updateExchangeData = (exchangeName: string, symbol: string, bag: number) => {
  let trouve = false;
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let item = porttcfolio.items[i];

    if (item.symbol.trim() === symbol.trim() && item.site && item.site.toUpperCase() === exchangeName.toUpperCase()) {
      if (item.automaticUpdate && item.bag !== bag) {
        if (Number(item.bag) !== Number(bag)) {
          item.updatedFromExchange = true;
        }

        item.bag = Number(bag);
        updatePortfolioInBrowserCache();
        console.log("UPDATED AUTOMATIC:" + symbol + " - " + item.bag + " / " + bag);
      }

      trouve = true;
    }
  }

  if ((!trouve)) {
    console.log("NON TROUVE [" + symbol + "]");
    let portfolioItem: CryptoTableRow = {
      ttcId: generateTtcId(),
      symbol: symbol,
      site: exchangeName,
      bag: Number(bag),
      targets: [],
      loaded: true,
      automaticUpdate: true,
      updatedFromExchange: true
    }
    porttcfolio.items.push(portfolioItem);
    updatePortfolioInBrowserCache();
  }
}

export const filterPorttcfolio = (coingeckoData: any) => {

  let target: any[];
  target = [];
  let portfolioItems = porttcfolio.items;
  if (coingeckoData.length === 0) {
    return portfolioItems;
  }
  for (let i = 0; i < portfolioItems.length; i++) {
    let portfolioItem = { ...portfolioItems[i] };
    let trouve = false;
    for (let j = 0; j < coingeckoData.length; j++) {
      let coingeckoDataRow = coingeckoData[j];

      if (coingeckoDataRow.symbol.toLowerCase() === portfolioItem.symbol.toLowerCase()
      ) {
        let newData: CryptoTableRow =
        {
          ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
          symbol: coingeckoDataRow.symbol,
          name: coingeckoDataRow.name,
          image: coingeckoDataRow.image ? coingeckoDataRow.image : portfolioItem.image,
          site: portfolioItem.site,
          bag: portfolioItem.bag,
          bagPrice: calculPrice(portfolioItem.bag, coingeckoDataRow.current_price),
          unitPrice: coingeckoDataRow.current_price ? coingeckoDataRow.current_price : 0,
          price_change_percentage_24h: isNaN(coingeckoDataRow.price_change_percentage_24h) ? Number(coingeckoDataRow.price_change_percentage_24h) : coingeckoDataRow.price_change_percentage_24h,
          ath: coingeckoDataRow.ath,
          market_cap: coingeckoDataRow.market_cap,
          market_cap_rank: coingeckoDataRow.market_cap_rank,
          history: portfolioItem.history,
          targets: processTargetData(coingeckoDataRow, portfolioItem),
          comment: portfolioItem.comment,
          invested: portfolioItem.invested,
          coingeckoId: mapCoinGeckoId((portfolioItem.coingeckoId == null || portfolioItem.coingeckoId === undefined || portfolioItem.coingeckoId === '') ? coingeckoDataRow.coingeckoId : portfolioItem.coingeckoId),
          loaded: false,
          automaticUpdate: coingeckoDataRow.automaticUpdate,
          updatedFromExchange: portfolioItem.updatedFromExchange
        }
        target.push(newData);
        trouve = true;
        break;
      }
    }
    if (!trouve) {
      let coingeckoId=portfolioItem.coingeckoId==null || portfolioItem.coingeckoId===undefined?null:portfolioItem.coingeckoId;
      let cryptoPrice = getCryptoExpiration(portfolioItem.symbol, portfolioItem.name === undefined ? '' : portfolioItem.name
      ,coingeckoId);
      if (cryptoPrice != null) {
        let newData: CryptoTableRow =
        {
          ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
          symbol: portfolioItem.symbol,
          name: portfolioItem.name !== undefined ? portfolioItem.name : portfolioItem.symbol,
          image: portfolioItem.image ? portfolioItem.image : cryptoPrice.image,
          site: portfolioItem.site,
          bag: portfolioItem.bag,
          bagPrice: calculPrice(portfolioItem.bag, cryptoPrice.value),
          unitPrice: cryptoPrice.value,
          price_change_percentage_24h: (cryptoPrice.price_change_percentage_24h == null ? 0 : (isNaN(cryptoPrice.price_change_percentage_24h) ? Number(cryptoPrice.price_change_percentage_24h) : cryptoPrice.price_change_percentage_24h)),
          market_cap: portfolioItem.market_cap,
          market_cap_rank: portfolioItem.market_cap_rank,
          ath: portfolioItem.ath,
          history: portfolioItem.history,
          targets: processTargetData(portfolioItem, portfolioItem),
          comment: portfolioItem.comment,
          invested: portfolioItem.invested,
          coingeckoId: mapCoinGeckoId((portfolioItem.coingeckoId == null || portfolioItem.coingeckoId === undefined || portfolioItem.coingeckoId === '') ? cryptoPrice.coingeckoId : portfolioItem.coingeckoId),
          loaded: false,
          automaticUpdate: portfolioItem.automaticUpdate,
          updatedFromExchange: portfolioItem.updatedFromExchange
        }

        target.push(newData);
        trouve = true;
      }
    }
    if (!trouve) {
      let currentPrice = 0;
      if (portfolioItem.unitPrice !== undefined) {
        currentPrice = portfolioItem.unitPrice;
      }
      let coingeckoId = null;
      if (portfolioItem.coingeckoId !== undefined && portfolioItem.coingeckoId != null) {
        coingeckoId = portfolioItem.coingeckoId;
      }

      let newData: CryptoTableRow =
      {
        ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
        symbol: portfolioItem.symbol,
        name: portfolioItem.name !== undefined ? portfolioItem.name : portfolioItem.symbol,
        image: portfolioItem.image ? portfolioItem.image : '',
        site: portfolioItem.site,
        bag: portfolioItem.bag,
        bagPrice: calculPrice(portfolioItem.bag, currentPrice),
        unitPrice: currentPrice,
        price_change_percentage_24h: (portfolioItem.price_change_percentage_24h == null ? 0 : (isNaN(portfolioItem.price_change_percentage_24h) ? Number(portfolioItem.price_change_percentage_24h) : portfolioItem.price_change_percentage_24h)),
        market_cap: 0,
        market_cap_rank: 0,
        ath: 0,
        history: '',
        targets: processTargetData(portfolioItem, portfolioItem),
        comment: portfolioItem.comment,
        invested: portfolioItem.invested,
        coingeckoId: mapCoinGeckoId((portfolioItem.coingeckoId == null || portfolioItem.coingeckoId === undefined || portfolioItem.coingeckoId === '') ? coingeckoId : portfolioItem.coingeckoId),
        loaded: false,
        automaticUpdate: portfolioItem.automaticUpdate,
        updatedFromExchange: portfolioItem.updatedFromExchange
      }

      target.push(newData);
    }
  }

  return target;
}


export const processTargetData = (srcData: any, filter: any) => {
  let result: TargetData[] = [];
  let targetData: TargetData[] = filter.targets;

  if (targetData) {
    for (let i = 0; i < targetData.length; i++) {
      let src: TargetData = targetData[i];
      let rate: number | null = (src.rate === undefined ? null : src.rate);
      if (src.bagPerc === undefined || src.bagPerc === null) {
        src.bagPerc = 20;
      } else if (typeof src.bagPerc === "string") {
        src.bagPerc = parseInt(src.bagPerc);
      }





      if (typeof src.rate === "string") {
        src.rate = parseFloat(src.rate);
      }

      let bagOfTarget = filter.bag * src.bagPerc / 100;
      result.push({
        rate: src.rate,
        perc: src.rate == null ? null : (srcData.current_price ? (srcData.current_price * 100 / src.rate) : (srcData.unitPrice * 100 / src.rate)),
        price: calculPrice(bagOfTarget, rate),
        bag: bagOfTarget,
        bagPerc: src.bagPerc,
        sold: src.sold,
        status: src.status,
        graphProcessed: false,
        reinvestedAmount: src.reinvestedAmount
      });
    }
  }

  return result;
}

export const canSwitchToAutomatic = (row: CryptoTableRow) => {
  for (let i = 0; i < row.targets.length; i++) {
    if (StatusEnum.Init !== row.targets[i].status) {
      return false;
    }
  }
  return true;
}

export const reprocessTargetsData = (row: CryptoTableRow) => {
  if (row.targets) {
    for (let i = 0; i < row.targets.length; i++) {
      let target = row.targets[i];
      let rate: number | null = (target.rate === undefined ? null : target.rate);
      let unitPrice = (row.unitPrice == null || row.unitPrice === undefined) ? 0 : row.unitPrice;

      if (target.bagPerc === undefined || target.bagPerc === null) {
        target.bagPerc = 20;
      }




      let bagOfTarget = row.bag * target.bagPerc / 100;


      target.bag = bagOfTarget;
      target.price = calculPrice(bagOfTarget, rate);
      target.perc = (rate == null || unitPrice === undefined) ? null : (unitPrice * 100 / rate);
    }

  }
}

