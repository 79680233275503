

export const isStableCoin = (symbol: string) => {
  if (symbol == null || symbol === undefined) {
    return false;
  } else if (symbol.toUpperCase() === 'USDT' || symbol.toUpperCase() === 'USDC') {
    return true;
  }
  else {
    return false;
  }
}

export const generateTtcId = () => {
  return '' + (new Date()).getTime() + '-' + Math.random();
}
export const usedBagPerc = (row: CryptoTableRow) => {
  let total: number = 0;
  if (row) {
    let targets = row.targets;
    if (targets) {
      for (let i = 0; i < targets.length; i++) {
        let target = targets[i];
        let bagPerc: number = (target.bagPerc === undefined || target.bagPerc == null) ? 0 : Number(target.bagPerc);
        total = total + bagPerc;
      }
    }
  }
  return total;
}

export const mapCoinGeckoId = (id: string | null) => {
  if (id == null) {
    return null;
  } else if (id === "0x0.ai") {
    return "0x0-ai-ai-smart-contract";
  } else {
    return id;
  }
}



export interface CryptoTableRow {
  ttcId?: string,
  name?: string,
  site: string,
  image?: any,
  symbol: string,
  bag: number,
  bagPrice?: number | null,
  unitPrice?: number,
  invested?: number;
  price_change_percentage_24h?: number,
  targets: TargetData[],
  comment?: string | null,
  coingeckoId?: string | null,
  history?: string,
  market_cap?: number,
  market_cap_rank?: number,
  ath?: number,
  loaded: boolean,
  automaticUpdate:boolean,
  updatedFromExchange:boolean
}

export interface TargetData {
  rate: number,
  price?: number,
  sold?: boolean | null,
  bag?: number,
  bagPerc?: number,
  perc?: number | null,
  status?: StatusEnum,
  graphProcessed?: boolean,
  reinvestedAmount?:number
}


export interface CryptoTableRows {
  items: CryptoTableRow[]
}

export interface Porttcfolio {
  items: CryptoTableRow[];
  investment: number;
  impotsPerc: number;
  solds?: number;/*not used anymore*/
  exchanges: Exchange[];
  currentTotal: number;
  transient: PorttcfolioTransient | null;
}
export interface PorttcfolioTransient{
  exchanges:ExchangeTotal[];
  cryptos:CryptoTotal[];
  cryptoTargets:CryptoTargetTotal[];
  cryptoSummary:CryptoSummary[];
  totalTarget:number;
  totalInvested?:number;
  totalReInvested?:number;
  totalSold?:number;
  totalSoldMinusReinvested?:number;
  totalBeforeTax?:number;
  totalTax?:number;
  totalAfterTax?:number;
  totalGains?:number;
}
export interface ExchangeTotal{
  exchange:string;
  value: number;
  exchangeBagPrice: number;
  label:string;
  label2:string;
}
export interface CryptoSummary{
  symbol:string;
  totalLeft:number;
  sold:number;
  reinvested:number;
}
export interface CryptoTotal{
  symbol:string;
  value: number;
  label:string;
  label2:string;
}
export interface CryptoTargetTotal{
  symbol:string;
  id:string;
  value: number;
  label:string;
  label2:string;
}
export interface Exchange{
  exchange: string;
  key: string;
  secret: string;
  passphrase: string;
  exclusions:string;
  buyUrl?:string;
  editable:boolean;
}

export let exchangesConfigArray:Exchange[] = [
  { exchange: 'BITGET', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore',editable:true ,buyUrl:'https://www.bitget.com/fr/spot/{placeholder}USDT'}
  ,{ exchange: 'BITMART', key: 'Votre clé Bitmart', secret: 'Votre secret Bitmart',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true, buyUrl:'https://www.bitmart.com/trade/en-US?symbol={placeholder}_USDT' }
  ,{ exchange: 'BITRUE', key: 'Votre clé Bitrue', secret: 'Votre secret Bitrue',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true, buyUrl:'https://www.bitrue.com/fr/trade/{placeholder}_usdt' }
  ,{ exchange: 'BLOFIN',key:'Votre clé Blofin',secret:'Votre secret Blofin',passphrase:'Votre passphrase',exclusions:'',editable:true,buyUrl:'https://blofin.com/spot/{placeholder}-USDT'}
  ,{ exchange: 'BYBIT',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://www.bybit.com/en/trade/spot/{placeholder}/USDT'}
  ,{ exchange: 'GATEIO', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore',editable:true,buyUrl:'https://www.gate.io/fr/trade/{placeholder}_USDT' }
  ,{ exchange: 'MEXC', key: 'Votre clé MEXC', secret: 'Votre secret MEXC',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true,buyUrl:'https://www.mexc.com/fr-FR/exchange/{placeholder}_USDT' }
  ,{ exchange: 'TRADEOGRE',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://tradeogre.com/exchange/{placeholder}-USDT'}  
  ,{ exchange: 'XT',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://www.xt.com/en/trade/{placeholder}_usdt'}  
];

export interface TotalTargetData {
  label: string;
  totalTarget: number;
  impots: number;
  gain: number;
  totalSold: number;
}


export enum StatusEnum {
  Init = 0,
  Order = 1,
  Sold = 2
}

export const colors2 = [
  'rgb(234,27,0)',
  'rgb(238,173,0)'
]

export const colors = [

  '#8dd3c7',

  '#ffffb3',

  '#bebada',

  '#fb8072',

  '#80b1d3',

  '#fdb462',

  '#b3de69',

  '#fccde5',

  '#d9d9d9',

  '#bc80bd',

  '#ccebc5',

  '#ffed6f',
  'orange'
];