import { porttcfolio } from "../data/CryptoData";

export const sumOrNull = (a: number | null | undefined, b: number | null | undefined) => {
    return ((a == null || a === undefined || isNaN(a))
        ? ((b == null || b === undefined || isNaN(b)) ? 0 : b)
        : (b == null || b === undefined || isNaN(b)) ? a : a + b);
}

export const valueFormatter = (value: number) => `$${value}`;

export const calculBarChartData = (arcLabel: any, arcLabelTarget: any, arcLabelExchange: any) => {
    if (porttcfolio.transient != null) {
        for (let i = 0; i < porttcfolio.transient.exchanges.length; i++) {
            let ct = porttcfolio.transient.exchanges[i];
            arcLabelExchange.push({ value: ct.value, label: ct.label2 });
        }
        for (let i = 0; i < porttcfolio.transient.cryptos.length; i++) {
            let ct = porttcfolio.transient.cryptos[i];
            arcLabel.push({ value: ct.value, label: ct.label2 });
        }
        for (let i = 0; i < porttcfolio.transient.cryptoTargets.length; i++) {
            let ct = porttcfolio.transient.cryptoTargets[i];
            arcLabelTarget.push({ value: ct.value, label: ct.label2 });
        }
    }
}