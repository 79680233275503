import axios from "axios";
import { Exchange } from "../../data/CryptoDataType";

const geekiExchangesRequest = async (exchanges:Exchange[]) => {
  let data=[];
  for(let i=0;i<exchanges.length;i++){
    let exc=exchanges[i];
    if (exc.editable){
      data.push(exc);
    }
  }
    try {
        const response = await axios.post('https://www.geeki.fr/api/crypto/accounts', {exchanges:data}, {
          withCredentials: false,
          headers: {
            'Accepts': 'application/json',
            'Access-Control-Allow-Credentials': true,
            "Access-Control-Allow-Origin": "*",
            'Access-Control-Allow-Headers': 'Origin'
          }
        });        
        return response;
      } catch (error) {
        console.error('Erreur lors de l\'appel API:', error);
      }
  }

  export default geekiExchangesRequest;