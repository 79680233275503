import { useState, forwardRef, useImperativeHandle } from 'react';
import "../Crypto.css";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { porttcfolio, updateExchange } from "../data/CryptoData";
import { updatePortfolioInBrowserCache } from '../data/CryptoCache';
import PasswordField from '../array/PasswordField ';
import { Exchange, exchangesConfigArray } from '../data/CryptoDataType';



const initAdminExchanges = () => {

    if (porttcfolio.exchanges) {

        for (let j = 0; j < exchangesConfigArray.length; j++) {
            let myObj:Exchange = exchangesConfigArray[j];
            let trouve = false;
            for (let i = 0; i < porttcfolio.exchanges.length; i++) {
                if (myObj.editable && porttcfolio.exchanges[i].exchange.toUpperCase() === myObj.exchange.toUpperCase()) {
                    trouve = true;
                    break;
                }
            }
            if (myObj.editable && !trouve) {
                porttcfolio.exchanges.push(myObj);
            }
        }
    } else {
        return exchangesConfigArray;
    }
    return porttcfolio.exchanges;
}

// Utilisation de forwardRef pour exposer des méthodes via ref
const Admin = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [exchangesRows, setExchangesRows] = useState(initAdminExchanges());

    // Exposer la fonction handleOpen via ref
    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));


    const handleChange = (index: any, field: any, value: any) => {
        if (exchangesRows !== undefined) {
            const updatedRows = exchangesRows.map((row, i) =>
                i === index ? { ...row, [field]: value } : row
            );
            setExchangesRows(updatedRows);
        }
    };

    const handleApply = () => {
       // console.log("Données appliquées:", exchangesRows);
        if (exchangesRows !== undefined) {
            for (let i = 0; i < exchangesRows.length; i++) {
                updateExchange(exchangesRows[i]);
            }
            updatePortfolioInBrowserCache();
            setOpen(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>Administration</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <form id="adminForm">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Exchange</TableCell>
                                    <TableCell>Clé</TableCell>
                                    <TableCell>Secret</TableCell>
                                    <TableCell>Passphrase</TableCell>
                                    <TableCell>Exclusion</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {(exchangesRows !== undefined) && exchangesRows.map((row, index) => (
                                    <TableRow key={index} style={{ display: (row.editable ? '' : 'none') }}>
                                        <TableCell>
                                            {row.exchange}
                                        </TableCell>
                                        <TableCell>
                                            <PasswordField value={row.key}
                                                label="Votre access key"
                                                onChange={(e: any) =>
                                                    handleChange(index, 'key', e.target.value)
                                                } />
                                        </TableCell>
                                        <TableCell>
                                            <PasswordField
                                                value={row.secret}
                                                label="Votre secret"
                                                onChange={(e: any) =>
                                                    handleChange(index, 'secret', e.target.value)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <PasswordField
                                                value={row.passphrase}
                                                label="Votre passphrase"
                                                onChange={(e: any) =>
                                                    handleChange(index, 'passphrase', e.target.value)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={row.exclusions}
                                                onChange={(e) =>
                                                    handleChange(index, 'exclusions', e.target.value)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </form>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <button onClick={() => setOpen(false)} className="ttcButton">
                    Annuler
                </button>
                <button onClick={handleApply} className="ttcButton">
                    Appliquer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default Admin;
