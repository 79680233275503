import React from "react";
import "../CryptoTable.css";
import { BarChart } from "@mui/x-charts";
import { CryptoTableRows, colors2 } from "../data/CryptoDataType";
import { Row, Col, Table } from "reactstrap";
import { calculBarChartData, valueFormatter } from "./CryptoGraphUtils";
import { TableBody } from "@mui/material";
import { porttcfolio } from "../data/CryptoData";

interface ChildProps {
    rows: CryptoTableRows;
    tutu?: any;
}

const chartSetting = {
    xAxis: [
        {
            label: 'Montant (USDT)',
        },
    ],
    width: 1000,
    height: 800,
};

let rowsToto: any = [];
let rowsTotoTarget: any = [];

const renderBarChart = () => {
    let dataTarget: any[] = (porttcfolio.transient != null && porttcfolio.transient.cryptoTargets) ? porttcfolio.transient.cryptoTargets : [];
    let dataCurrent: any[] = (porttcfolio.transient != null && porttcfolio.transient.cryptos) ? porttcfolio.transient.cryptos : [];
    let data: any[] = [];

    let total = 0;
    for (let item of dataCurrent) {
        for (let itemTarget of dataTarget) {
            if (item.symbol === itemTarget.symbol) {
                data.push({ label2: item.label2, value: item.value, maxTarget: itemTarget.value });
                break;
            }
        }
        total += item.total;
    }
    return <BarChart
        dataset={data}
        colors={colors2}
        yAxis={[{ scaleType: 'band', dataKey: 'label2' }]}
        series={[
            { dataKey: 'value', label: 'Montant actuel (' + total.toFixed(0) + ' USDT)', valueFormatter: valueFormatter },
            { dataKey: 'maxTarget', label: 'Objectif (' + porttcfolio.transient?.totalTarget.toFixed(0) + ' USDT)', valueFormatter: valueFormatter }
        ]}
        layout="horizontal"
        {...chartSetting}
    />;
}

const CryptoMultiBarPerSymbol: React.FC<ChildProps> = (props: ChildProps) => {
    calculBarChartData(rowsToto, rowsTotoTarget, []);

    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>
                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoPieItemExpected">
                            Portfolio
                        </Col>
                    </Row>
                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoBarItem">
                            {renderBarChart()}
                        </Col>
                    </Row>
                </TableBody>
            </Table></span>);
};

export default CryptoMultiBarPerSymbol;