import { IMPOTS_DEFAULT_PERC } from "./CryptoConfig";
import { Porttcfolio, Exchange, CryptoTargetTotal, exchangesConfigArray, StatusEnum, TargetData, CryptoTableRow, CryptoSummary } from "./CryptoDataType";

export const initTransientData = (headerRef: any) => {
  resetTransient();



  for (let item of porttcfolio.items) {

    let price = item.bagPrice ? parseFloat(item.bagPrice.toFixed(2)) : 0;
    let trouve = false;
    if (porttcfolio.transient != null && porttcfolio.transient !== undefined) {
      for (let exc of porttcfolio.transient.exchanges) {
        if (exc.exchange === item.site.toUpperCase()) {
          exc.value = exc.value + Number(price.toFixed(0));
          exc.label = item.site.toUpperCase() + ' $' + exc.value;
          exc.exchangeBagPrice = exc.exchangeBagPrice + (item.bagPrice == null ? 0 : item.bagPrice);
          trouve = true;
        }
      }

      if (!trouve) {
        porttcfolio.transient.exchanges.push({
          exchange: item.site.toUpperCase()
          , exchangeBagPrice: (item.bagPrice == null ? 0 : item.bagPrice)
          , value: Number(price.toFixed(0))
          , label: item.site.toUpperCase() + ' $' + price
          , label2: item.site.toUpperCase()
        });
      }

      trouve = false;
      for (let exc of porttcfolio.transient.cryptos) {
        if (exc.symbol === item.symbol.toUpperCase()) {
          exc.value = exc.value + Number(price.toFixed(0));
          exc.label = item.symbol.toUpperCase() + ' $' + exc.value;
          trouve = true;
        }
      }

      if (!trouve) {
        porttcfolio.transient.cryptos.push({
          symbol: item.symbol.toUpperCase()
          , value: Number(price.toFixed(0))
          , label: item.symbol.toUpperCase() + ' $' + price
          , label2: item.symbol.toUpperCase()
        });
      }


      let total = 0;
      let sumPerc = 0;

      for (let i = 0; i < item.targets.length; i++) {
        let rate = (item.targets[i].rate == null || item.targets[i].rate === undefined ? null : item.targets[i].rate);
        let targetPrice = calculPrice(item.targets[i].bag, rate);
        total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
        let bagPerc = item.targets[i].bagPerc === undefined ? 0 : item.targets[i].bagPerc;
        if (bagPerc !== undefined) {
          sumPerc += bagPerc;
        }

        //todo cst calcul vendu et reinvest

      }
      if (sumPerc < 100 && item.targets.length > 0) {
        let rate = (item.targets[item.targets.length - 1].rate == null || item.targets[item.targets.length - 1].rate === undefined ? null : item.targets[item.targets.length - 1].rate);
        let bag = item.targets[item.targets.length - 1].bag;
        if (bag !== undefined) {
          let targetPrice = calculPrice(bag * (100 - sumPerc) / 100, rate);
          total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
        }
      }

      trouve = false;
      for (let ct of porttcfolio.transient.cryptoTargets) {
        if (ct.symbol.toUpperCase() === item.symbol.toUpperCase()) {
          ct.value = ct.value + Number(total.toFixed(0));
          ct.label = item.symbol.toUpperCase() + ' $' + ct.value;
          trouve = true;
        }
      }

      if (!trouve) {

        let cryptoTarget: CryptoTargetTotal = {
          symbol: item.symbol
          , id: item.symbol + Math.random()
          , value: Number(total.toFixed(0))
          , label: item.symbol + ' $' + total.toFixed(0)
          , label2: item.symbol
        };
        porttcfolio.transient.cryptoTargets.push(cryptoTarget);
      }
      porttcfolio.transient.totalTarget += total;

    }


  }

  initTransientTotals();


  console.log("TODO CST headerRef");
  console.log(headerRef);
  if (headerRef && headerRef.current) {
    headerRef.current.refreshAmounts();
  }


  if (porttcfolio.transient != null) {
    porttcfolio.transient.exchanges = porttcfolio.transient.exchanges.sort((a: any, b: any) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }
}

export const initTransientTotals = () => {
  let totalSold = 0;
  let totalReInvested = 0;
  let totalBeforeTax = 0;
  console.log("initTransientTotals");
  console.log(porttcfolio.items);

  let investment = (porttcfolio.investment ? Number(porttcfolio.investment) : 0);
  let summary: CryptoSummary[] = [];
  if (porttcfolio.transient) {
    porttcfolio.transient.cryptoSummary = summary;
  }
  for (let item of porttcfolio.items) {
    let itemSummary: CryptoSummary = { symbol: '', totalLeft: 0, sold: 0, reinvested: 0 };
    for (let cs of summary) {
      if (cs.symbol === item.symbol) {
        itemSummary = cs;
        break;
      }
    }
    if (itemSummary.symbol === '') {
      itemSummary = { symbol: item.symbol, totalLeft: 0, sold: 0, reinvested: 0 };
      summary.push(itemSummary);
    }



    console.log(item);
    if (item.targets.length == 0) {
      itemSummary.totalLeft += (item.bagPrice==null || item.bagPrice===undefined?0:item.bagPrice);
    } else {
      for (let i = 0; i < item.targets.length; i++) {
        let target = item.targets[i];
        console.log(target);
        if (target.status === StatusEnum.Sold) {
          totalSold += Number(target.price);
          itemSummary.sold += Number(target.price);
          if (target.reinvestedAmount && Number(target.reinvestedAmount) > 0) {
            totalReInvested += Number(target.reinvestedAmount);
            itemSummary.reinvested += Number(target.reinvestedAmount);
          }
        } else {
          //console.log(target.price);
          itemSummary.totalLeft += initTransientTargetPrice(item, target);
        }
        totalBeforeTax += initTransientTargetPrice(item, target);

      }
    }
  }


  if (porttcfolio.transient) {

    totalBeforeTax=0;
    totalSold=0;
    totalReInvested=0;
    for( let cryptoSummary of summary){
      totalSold+=cryptoSummary.sold;
      totalReInvested+=cryptoSummary.reinvested;
      totalBeforeTax+=(cryptoSummary.totalLeft+cryptoSummary.sold-cryptoSummary.reinvested);
    }

    porttcfolio.transient.totalInvested = investment;
    porttcfolio.transient.totalSold = totalSold;
    porttcfolio.transient.totalReInvested = totalReInvested;
    porttcfolio.transient.totalSoldMinusReinvested = totalSold - totalReInvested;
    porttcfolio.transient.totalBeforeTax = totalBeforeTax;

    let impotsPerc = (porttcfolio.impotsPerc ? Number(porttcfolio.impotsPerc) : IMPOTS_DEFAULT_PERC);;

    let totalTax = (totalBeforeTax - investment) * impotsPerc / 100;
    totalTax = (totalTax < 0 ? 0 : totalTax);
    porttcfolio.transient.totalTax = totalTax;

    let totalAfterTax = totalBeforeTax - totalTax;
    porttcfolio.transient.totalAfterTax = totalAfterTax;

    let totalGains = totalTax - investment - totalTax;
    totalGains = (totalGains < 0 ? 0 : totalGains);
    porttcfolio.transient.totalGains = totalGains;
  }
}

export const initTransientTargetPrice = (item: CryptoTableRow, target: TargetData) => {
  let bagOfTarget = (target.bagPerc ? Number(item.bag) * Number(target.bagPerc) / 100 : 0);
  if (target.status === StatusEnum.Sold) {
    let soldTotal = Number(bagOfTarget) * Number(target.rate);
    if (target.reinvestedAmount) {
      return Number(soldTotal) - Number(target.reinvestedAmount);
    } else {
      return Number(soldTotal);
    }
  } else {
    return (item.unitPrice ? (Number(item.unitPrice) * bagOfTarget) : 0)
    // return (target.price?Number(target.price):0);
  }
}

const resetTransient = () => {

  if (porttcfolio.transient != null) {
    porttcfolio.transient.exchanges = [];
    porttcfolio.transient.cryptos = [];
    porttcfolio.transient.cryptoTargets = [];
    porttcfolio.transient.totalTarget = 0;
  } else {
    porttcfolio.transient = {
      exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
      , totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
    };




  }
}

export const reloadPorttcfolioFromJson = (value: string, headerRef: any) => {

  try {
    portfolio = JSON.parse(value);
  } catch (e) {
    alert("Mauvais ancien format");
  }

  try {
    if (value.indexOf("investment") >= 0) {
      porttcfolio = JSON.parse(value);

      let total = 0;
      for (let i = 0; i < porttcfolio.items.length; i++) {
        let item = porttcfolio.items[i];
        let price = item.bagPrice;
        if (price != null) {
          total += price;
        }

      }
      porttcfolio.currentTotal = total;
      initTransientData(headerRef);
      cleanExchanges();
    } else {
      porttcfolio = {
        currentTotal: 0,
        investment: 0,
        impotsPerc: 33,
        items: JSON.parse(value),
        exchanges: [],
        transient: {
          exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
          , totalInvested: 0
          , totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
        }
      };
    }
  } catch (e) {

    if (value.indexOf("investment") >= 0) {
      let value1 = '{"currentTotal": 0,"impotsPerc": 33, "exchanges": [], "transient": { "exchanges": [], "cryptos": [], "cryptoTargets": [], "totalTarget": 0 },' + value.substring(1);
      value1 = value1.replaceAll("totalPrice", "\"loaded\":false,\"automaticUpdate\":true,\"updatedFromExchange\":false,\"bagPrice");
      value1 = value1.replaceAll('""', '"');
      const regex = /,"notificationHigh\d+":\d+/g;
      value1 = value1.replace(regex, '');


      try {
        porttcfolio = JSON.parse(value1);
        let total = 0;
        for (let i = 0; i < porttcfolio.items.length; i++) {
          let item = porttcfolio.items[i];
          let price = item.bagPrice;
          if (price != null) {
            total += price;
          }
        }
        porttcfolio.currentTotal = total;
        initTransientData(headerRef);
        cleanExchanges();
      } catch (ex) {
        alert("Mauvais nouveau format");
        console.log(value1);
        porttcfolio = {
          currentTotal: 0,
          investment: 0,
          impotsPerc: 33,
          items: [],
          exchanges: [],
          transient: {
            exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
            , totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
          }
        };
      }
    } else {
      porttcfolio = {
        currentTotal: 0,
        investment: 0,
        impotsPerc: 33,
        items: [],
        exchanges: [],
        transient: {
          exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
          , totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
        }
      };
    }



  }

}

const cleanExchanges = () => {
  let data = [];
  for (let i = 0; i < porttcfolio.exchanges.length; i++) {
    let obj = porttcfolio.exchanges[i];
    let trouve = false;
    for (let j = 0; j < data.length; j++) {
      if (data[j].exchange === obj.exchange) {
        trouve = true;
        break;
      }
    }
    if (!trouve) {
      data.push(obj);
      for (let j = 0; j < exchangesConfigArray.length; j++) {
        let myObj: Exchange = exchangesConfigArray[j];
        if (myObj.exchange === obj.exchange.toUpperCase()) {
          obj.editable = myObj.editable;
        }
      }

    }
  }
  porttcfolio.exchanges = [];
  for (let j = 0; j < data.length; j++) {
    porttcfolio.exchanges.push(data[j]);
  }

}



//todo cst old format
export let portfolio = [
  { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "USDT", "site": "bybit", "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "TAO", "site": "blofin", "bag": 5, "invested": "200", "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu" },
  { "symbol": "BTC", "site": "bybit", "bag": 5, "targets": [{ "rate": 70000 }] },
  { "symbol": "ETH", "site": "ledger", "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }] },
  { "symbol": "XRP", "site": "metamask", "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }] },
  { "symbol": "AKT", "site": "blofin", "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }] }
];

export let porttcfolio: Porttcfolio =
{
  currentTotal: 0,
  investment: 0,
  impotsPerc: 33,
  items: [
    { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "updatedFromExchange": false, "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false, automaticUpdate: true },
    { "symbol": "USDT", "site": "bybit", "updatedFromExchange": false, "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false, automaticUpdate: true },
    { "symbol": "TAO", "site": "blofin", "updatedFromExchange": false, "bag": 5, "invested": 200, "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu", loaded: false, automaticUpdate: true },
    { "symbol": "BTC", "site": "bybit", "updatedFromExchange": false, "bag": 5, "targets": [{ "rate": 70000 }], loaded: false, automaticUpdate: true },
    { "symbol": "ETH", "site": "ledger", "updatedFromExchange": false, "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }], loaded: false, automaticUpdate: true },
    { "symbol": "XRP", "site": "metamask", "updatedFromExchange": false, "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }], loaded: false, automaticUpdate: true },
    { "symbol": "AKT", "site": "blofin", "updatedFromExchange": false, "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }], loaded: false, automaticUpdate: true }
  ],
  exchanges: [],
  transient: {
    exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
    , totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
  }
};

export const calculPrice = (bag: number | undefined, rate: number | null) => {
  if (rate == null) {
    return 0;
  }
  if (bag !== undefined) {
    let result = bag * rate;
    if (Number.isNaN(result) || result === Infinity) {
      return 0;
    } else {
      return result;
    }
  } else {
    return 0;
  }
}

export const updateExchange = (row: any) => {
  if (row.editable) {
    let trouve = false;
    if (porttcfolio.exchanges) {
      for (let i = 0; i < porttcfolio.exchanges.length; i++) {
        let obj = porttcfolio.exchanges[i];
        if (obj.exchange === row.exchange) {
          obj.key = row.key;
          obj.secret = row.secret;
          obj.passphrase = row.passphrase;
          obj.exclusions = row.exclusions;

          trouve = true;
        }
      }
    } else {
      porttcfolio.exchanges = [];
    }
    if (!trouve) {
      let obj: Exchange = { exchange: row.exchange, key: row.key, secret: row.secret, passphrase: row.passphrase, exclusions: row.exclusions, editable: row.editable };
      porttcfolio.exchanges.push(obj);
    }
  }
}

export const getPositionInPorttcfolio = (ttcId: string, porttcfolio: Porttcfolio) => {
  return getPositionInSortedRows(ttcId, porttcfolio.items);
}

export const getPositionInSortedRows = (ttcId: string, sortedRows: any) => {
  for (let i = 0; i < sortedRows.length; i++) {
    if (sortedRows[i].ttcId === ttcId) {
      return i;
    }
  }
  return -1;
}

export const toFixed = (value: any, precision: number) => {
  if (value == null || value === undefined) {
    return null;
  } else if (isNaN(value)) {
    return Number(value).toFixed(precision);
  } else {
    let valueNumber: Number = value;
    return valueNumber.toFixed(precision);
  }
}


