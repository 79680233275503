import React, { useState, useEffect, useRef } from "react";
import "./Crypto.css";
import CryptoHeader from "./CryptoHeader";
import { porttcfolio, reloadPorttcfolioFromJson } from "./data/CryptoData";
import CryptoContent from "./CryptoContent";
import { initPorttcfolio, filterPorttcfolio, sortPorttcfolio } from "./data/CryptoProcessData";
import { CryptoTableRows } from "./data/CryptoDataType";
import { queryCoinGecko } from "./data/CryptoQuery";
import logo from '../cryptoverview.png';
import { DISPLAY_MODE_ARRAY, DISPLAY_MODE_GRAPH } from "./data/CryptoConfig";


export const CryptoPage = () => {

  const [ttcCryptos, setTtcCryptos] = useState<CryptoTableRows>({ items: [] });
  const [search, setSearch] = useState("");
  const [processing, setProcessing] = useState(true);
  let [viewMode, setViewMode] = React.useState(true);
  let [displayMode, setDisplayMode] = React.useState(DISPLAY_MODE_ARRAY);


  const fullRefresh = () => {
    console.log("fullRefresh");
      setProcessing(true);
    
    initPorttcfolio();

    for (let i = 0; i < porttcfolio.items.length; i++) {
      (porttcfolio.items[i] as any).loaded = false;
    }

    sortPorttcfolio();
    filterPorttcfolio( '');
    queryCoinGecko(setTtcCryptos, filterData);
  }

  const partialRefresh = (needToSort: boolean) => {
    console.log("partialRefresh");
      setProcessing(true);
    if (needToSort) {
      sortPorttcfolio();
    }
    setTtcCryptos({ items: filterData('') });
  }

  const filterData = (src: any) => {
    console.log("filterdata processing -->false");
      setProcessing(false);
    return filterPorttcfolio( src);
  }


  //init with data from cache
  const headerRef = useRef(null);
  let data = localStorage.getItem("cryptool");
  
  useEffect(() => {
    console.log("useeffect");
    if (data != null) {
      console.log("reloadPorttcfolioFromJson 1");
      reloadPorttcfolioFromJson(data,headerRef);
    }


    initPorttcfolio();
    queryCoinGecko(setTtcCryptos, filterData);
  }, []);

  type MaFonction = (param: any) => void;

  const handleSearch: MaFonction = (e: any) => {
    console.log("handlesearch");
    setSearch(e.target.value);
  };
  
  const filteredttcCryptos = ttcCryptos.items.filter((ttcCrypto: any) =>
    (ttcCrypto.name && ttcCrypto.name.toLowerCase().includes(search.toLowerCase()))
    || (ttcCrypto.symbol && ttcCrypto.symbol.toLowerCase().includes(search.toLowerCase()))
  );


  let filtered: CryptoTableRows = { items: [] }
  filtered.items = filteredttcCryptos;

  const toggleViewMode = () => {
    console.log("toggleviewmode");
    let vm=!viewMode;
    setViewMode(!viewMode);
    if (vm) {
      fullRefresh();
      setSearch('');
    } else {
      partialRefresh(true);
    }
  }

  const toggleDisplayMode = () => {
    console.log("toggleDisplayMode");
    if (displayMode === DISPLAY_MODE_ARRAY) { setDisplayMode(DISPLAY_MODE_GRAPH); }
    else { setDisplayMode(DISPLAY_MODE_ARRAY); }
  }

  const buyPopupRef = useRef(null);

  return (
    <div className="App-header viewMode" id="App-header">
      <div className="crypto-array">
        <CryptoHeader cryptoRows={filtered} viewMode={viewMode} displayMode={displayMode}
          toggleViewMode={toggleViewMode} toggleDisplayMode={toggleDisplayMode}
          partialRefresh={partialRefresh} fullRefresh={fullRefresh}
          ref={headerRef}
          handleChange={handleSearch} />
        <CryptoContent cryptoRows={filtered} viewMode={viewMode} displayMode={displayMode}
          buyPopupRef={buyPopupRef}
          partialRefresh={partialRefresh} fullRefresh={fullRefresh} />
      </div>
      {processing && <div id="processing">
      <div className="crypto-logo"><img src={logo} className="App-logo" alt="logo" /></div></div>}
        {processing && <div id="processing2"><div className="crypto-logo-text"><br/><br/><br/><br/>Traitement...</div></div>}
    </div>
  );
}

export default CryptoPage;
