import { MenuItem, Select } from "@mui/material";
import React from "react";
import { StatusEnum } from "../data/CryptoDataType";
import { reprocessTargetsData } from "../data/CryptoProcessData";
import { getPositionInPorttcfolio, porttcfolio } from "../data/CryptoData";
import { updatePortfolioInBrowserCache } from "../data/CryptoCache";

interface IProps {
  targetPos: any;
  val: any;
}
interface IState {
  value: StatusEnum;
}


class StatusEditor extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    let initValue = this.props.val.targets[this.props.targetPos]["status"] == null ? StatusEnum.Init : (this.props.val.targets[this.props.targetPos]["status"] ? this.props.val.targets[this.props.targetPos]["status"] : StatusEnum.Init);
    this.state = {
      value: initValue
    };
  }

  handleChange = (event:any) => {
    let newValue = Number((event.target as HTMLInputElement).value);
    let targetPos=this.props.val.targets[this.props.targetPos];

    targetPos["status"] = newValue;
    targetPos["sold"] = (newValue === StatusEnum.Sold);

    if(StatusEnum.Order || StatusEnum.Sold){
      this.props.val.automaticUpdate=false;// we must keep the bag value, must not be updated buy job
    }

    reprocessTargetsData(this.props.val);
    let pos = getPositionInPorttcfolio(this.props.val.ttcId, porttcfolio);
    porttcfolio.items[pos] = this.props.val;
    updatePortfolioInBrowserCache();
    this.setState({ value: newValue });
  }


  render() {
    let targetData = this.props.val.targets[this.props.targetPos]["status"];

    return (<Select
      className="statusEditor2"
      labelId={`select-label-${this.props.val.ttcId}-${this.props.targetPos}`}
      value={targetData}
      onChange={this.handleChange}
    >
      <MenuItem value={StatusEnum.Init}>Initial</MenuItem>
      <MenuItem value={StatusEnum.Order}>Ordre de vente</MenuItem>
      <MenuItem value={StatusEnum.Sold}>Vendu</MenuItem>
    </Select>);

  }

}



export default StatusEditor;

