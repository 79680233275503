import { forwardRef, useRef, useState } from "react";
import "./CryptoTable.css";
import "./Crypto.css";
import { porttcfolio, reloadPorttcfolioFromJson } from "./data/CryptoData";
import CryptoHelp from "./CryptoHelp";
import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CalculateIcon from '@mui/icons-material/Calculate';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import BarChartIcon from '@mui/icons-material/BarChart';
import CryptoHeaderGraph from "./graph/CryptoHeaderGraph";
import { DISPLAY_MODE_ARRAY, DISPLAY_MODE_GRAPH } from "./data/CryptoConfig";
import SearchIcon from '@mui/icons-material/Search';
import cryptoLogo from '../cryptoverview.png';
import SettingsIcon from '@mui/icons-material/Settings';
import Admin from "./admin/Admin";
import CryptoHeaderSummary from "./CryptoHeaderSummary";
import CryptoHeaderSummaryDialog from "./CryptoHeaderSummaryDialog";

const CryptoHeader = forwardRef(( props:any,ref:any  ) => {
  const helpRef = useRef(null);
  const adminRef = useRef(null);
  const summaryRef = useRef(null);


  const toggleHelp = () => {
    if (helpRef.current != null) {
      (helpRef.current as any).toggleMe();
    }
  }

  const toggleEditMode: any = () => {
    hideBubbles();
    let appHeader = document.getElementById("App-header");
    if (appHeader != null) {
      appHeader.classList.toggle("viewMode");
    }
    props.toggleViewMode();
  }

  const toggleDisplayMode: any = () => {
    props.toggleDisplayMode();
  }



  const showBubbles = () => {
    document.getElementById("bubbles")!.classList.remove("hide");
  }
  const hideBubbles = () => {
    document.getElementById("bubbles")!.classList.add("hide");
  }

  const doAdmin = () => {
    if (adminRef.current) {
      (adminRef.current as any).handleOpen();
    }
  }

  const [updated, setUpdated] = useState(true);

  const refreshAmounts = () => {
    (summaryRef.current as any).refreshAmounts();
  }

  const summary1Ref = useRef(null);
  const doDetails = () => {
    if (summary1Ref.current) {
      (summary1Ref.current as any).handleOpen();
    }
  }

  const saveData = () => {

    let textarea = document.getElementById("dataEditor") as HTMLTextAreaElement;
    localStorage.setItem("cryptool", textarea.value);
    console.log("reloadPorttcfolioFromJson 2");
    reloadPorttcfolioFromJson(textarea.value,null);
    if (document.getElementById("dataEditorPanel") != null) {
      document.getElementById("dataEditorPanel")!.classList.remove("show");
    }
    props.fullRefresh();
  }



  return (
    <div className="crypto-header">
      <div className="crypto-header-buttons">
        <span className="crypto-title"><img src={cryptoLogo} alt="Cryptoverview"
          style={{ verticalAlign: "middle" }} height="50" width="50" />RYPTOVERVIEW</span>
        <span>

          <Tooltip title="Rafraichir">
            <IconButton onClick={props.fullRefresh}>
              <ReplayCircleFilledIcon />
            </IconButton>
          </Tooltip>

          {props.displayMode === DISPLAY_MODE_ARRAY &&
            <Tooltip title="Basculer en Mode Graphiques">
              <IconButton onClick={toggleDisplayMode}>
                <BarChartIcon />
              </IconButton>
            </Tooltip>}

          {props.displayMode === DISPLAY_MODE_GRAPH &&
            <Tooltip title="Basculer en Mode Tableau">
              <IconButton onClick={toggleDisplayMode}>
                <BallotIcon />
              </IconButton>
            </Tooltip>}

          <Tooltip title="Résuma financier">
              <IconButton onClick={doDetails}>
                <CalculateIcon />
              </IconButton>
            </Tooltip>

        

          <Tooltip title="Afficher les bulles">
            <IconButton onClick={showBubbles}>
              <BubbleChartOutlinedIcon />
            </IconButton>
          </Tooltip>

          {props.displayMode === DISPLAY_MODE_ARRAY && <button className="ttcButton" onClick={toggleEditMode}>{props.viewMode ? "Editer tableau" : "Fin édition"}</button>}

          <Tooltip title="Uploader portefeuille">
            <IconButton onClick={displayData}>
              <FileUploadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Downloader portefeuille">
            <IconButton onClick={downloadData}>
              <DownloadForOfflineIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Administration">
            <IconButton onClick={doAdmin}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Aide">
            <IconButton onClick={toggleHelp}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </span>
        {props.displayMode === DISPLAY_MODE_ARRAY && <span className="crypto-header-search">
          <Tooltip title="Rechercher une crypto-monnaie">
            <SearchIcon />
          </Tooltip>:&nbsp;
          <input
            type="text"
            onChange={props.handleChange}
            placeholder="Exemple: Bitcoin ou BTC"
          />
        </span>}
        <div className="crypto-header-edit-data" id="dataEditorPanel">
          <br />
          <textarea id="dataEditor" cols={100} rows={20}></textarea>
          <br />


          <button onClick={openCheckData} className="ttcButton">Vérifier json</button>

          <Tooltip title="Aide">
            <IconButton onClick={toggleHelp}>
              <HelpIcon />
            </IconButton>
          </Tooltip>


          <Tooltip title="Enregistrer">
            <IconButton onClick={saveData}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Annuler">
            <IconButton onClick={cancelData}>
              <Cancel />
            </IconButton>
          </Tooltip>

        </div>
      </div>
      <div className="crypto-header-sub">
        <CryptoHeaderSummary 
        ref={summaryRef}
        viewMode={props.viewMode} impotsPerc={props.impotsPerc}/>
        
        <CryptoHeaderGraph cryptoRows={props.cryptoRows} investment={porttcfolio.investment} />
      </div>
      <CryptoHelp open={false} ref={helpRef} />
      <Admin ref={adminRef} />
      <CryptoHeaderSummaryDialog ref={summary1Ref}
        impotsPerc={props.impotsPerc}
        viewMode={props.viewMode}/>
    </div>
  );
});

const displayData = () => {
  if (document.getElementById("dataEditorPanel") != null) {
    document.getElementById("dataEditorPanel")!.classList.add("show");
  }
  let textarea = document.getElementById("dataEditor") as HTMLTextAreaElement;
  textarea.value = JSON.stringify(porttcfolio);

}

const downloadData = () => {
  let value = JSON.stringify(porttcfolio);
  const blob = new Blob([value], { type: 'application/json' });

  const a = document.createElement('a');
  a.download = 'portefeuille.json';
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();

}


const cancelData = () => {
  if (document.getElementById("dataEditorPanel") != null) {
    document.getElementById("dataEditorPanel")!.classList.remove("show");
  }
}


const openCheckData = () => {
  window.open("https://jsonlint.com/");
}



export default CryptoHeader;